import React, { useState } from "react";
import styled from "styled-components";
import html2canvas from "html2canvas";
import FirstSubPage from "./SubPages/FirstSubPage";
import SecondSubPage from "./SubPages/SecondSubPage";
import ThirdSubPage from "./SubPages/ThirdSubPage";
import { SlCamera } from "react-icons/sl";
import { RxPerson } from "react-icons/rx";
import { BsTelephone } from "react-icons/bs";
import { IoMailOutline } from "react-icons/io5";
import invite from "./Assets/advert.jpg";

const MainPage = () => {
  const [showHome, setshowHome] = useState<boolean>(true);
  const dropHome = () => {
    setshowHome(true);
    setshowDetails(false);
    setshowCard(false);
  };
  const [showDetails, setshowDetails] = useState<boolean>(false);
  const dropDetails = () => {
    setshowDetails(true);
    setshowHome(false);
    setshowCard(false);
  };
  const [showCard, setshowCard] = useState<boolean>(false);
  const dropCard = () => {
    setshowCard(true);
    setshowHome(false);
    setshowDetails(false);
  };
  const [image, setImage] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [surname, setsurName] = useState<string>("");
  const displayPix = (e: any) => {
    const file = e.target.files[0];
    const save = URL.createObjectURL(file);
    setImage(save);
    console.log(save);
  };
  const checkName = name.replaceAll(" ", "").length;
  const printRef: any = React.useRef();

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };
  return (
    <div>
      <Container>
        <Head>
          <Logo>CODELAB</Logo>
          <Button>powered by set06</Button>
        </Head>
        <MenuRoutes>
          <MenuWrap>
            <Home borderBottom={showHome ? "nni" : ""} onClick={dropHome}>
              Home
            </Home>
            <Details
              borderBottom={showDetails ? "nni" : ""}
              onClick={dropDetails}>
              Your Details
            </Details>
            <Card borderBottom={showCard ? "nni" : ""} onClick={dropCard}>
              Invite Card
            </Card>
          </MenuWrap>
        </MenuRoutes>
        <SubPages>
          {showHome ? (
            <div>
              <Containerx>
                <Wrapperx ref={printRef}>
                  <HoldImage>
                    <EventInvite src={invite} />
                  </HoldImage>
                  <HoldDetails>
                    <Text>You're invited...</Text>
                  </HoldDetails>
                </Wrapperx>
                <Down onClick={dropDetails}>Click to Accept</Down>
              </Containerx>
            </div>
          ) : null}
          {showDetails ? (
            <div>
              <Container1>
                <Head1>
                  <TextHead>Profile</TextHead>
                </Head1>
                <Wrapper>
                  <SideBar>
                    <SideBarWrap>
                      <ImageWrap>
                        <Image src={image} />
                      </ImageWrap>
                      <DisplayName>
                        <strong>{name}</strong>
                      </DisplayName>
                      <DisplayName>
                        <strong>{surname}</strong>
                      </DisplayName>
                      <SideBarImageFill></SideBarImageFill>
                    </SideBarWrap>
                  </SideBar>
                  <MainBar>
                    <Inputs>
                      <ImageLabel htmlFor="pix">
                        <SlCamera />
                        <Button1 htmlFor="pix">Upload Image</Button1>
                      </ImageLabel>
                      <InputWrap>
                        <Icon>
                          <RxPerson />
                        </Icon>
                        <Names>
                          <Input
                            placeholder="First name"
                            value={name}
                            onChange={(e: any) => {
                              setName(e.target.value);
                            }}
                          />
                        </Names>
                      </InputWrap>
                      <InputWrap>
                        <Icon></Icon>
                        <Names>
                          <Input2
                            placeholder="Last name"
                            value={surname}
                            onChange={(e: any) => {
                              setsurName(e.target.value);
                            }}
                          />
                        </Names>
                      </InputWrap>
                      <InputWrap>
                        <BsTelephone />
                        <Names>
                          <Input placeholder="Phone" />
                        </Names>
                      </InputWrap>
                      <InputWrap>
                        <IoMailOutline />
                        <Names>
                          <Input placeholder="Mail" />
                        </Names>
                      </InputWrap>

                      <ImageInput
                        id="pix"
                        type="file"
                        accept="image/jpg, image/png, image/jpeg"
                        onChange={displayPix}
                      />
                    </Inputs>
                  </MainBar>
                  {checkName >= 4 && image !== "" ? (
                    <Nextbutton onClick={dropCard}>
                      See your invite card
                    </Nextbutton>
                  ) : null}
                </Wrapper>
              </Container1>
            </div>
          ) : null}
          {showCard ? (
            <div>
              <Containerx>
                <Wrapperx ref={printRef}>
                  <HoldImage>
                    <EventInvite src={invite} />
                  </HoldImage>
                  <HoldDetails>
                    <ImageHold>
                      <Imagex src={image} />
                    </ImageHold>
                    <Name>
                      {name} {surname}
                    </Name>
                    <Text>I will be attending...</Text>
                  </HoldDetails>
                </Wrapperx>
                <Down onClick={handleDownloadImage}>Download Card</Down>
              </Containerx>
            </div>
          ) : null}
        </SubPages>
      </Container>
    </div>
  );
};

export default MainPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1d1d1d;
`;
const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
`;
const Logo = styled.div`
  margin-left: 50px;
  font-weight: bold;
  color: white;
  @media screen and (max-width: 475px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 190px) {
    margin-left: 5px;
  }
`;
const Button = styled.button`
  margin-right: 50px;
  color: #474747;
  padding: 10px;
  font-size: small;
  background-color: #2c2c2c;
  border-radius: 20px;
  border: none;
  @media screen and (max-width: 475px) {
    margin-right: 10px;
  }
  @media screen and (max-width: 190px) {
    margin-right: 5px;
  }
`;
const MenuRoutes = styled.div`
  padding-top: 20px;
  color: #979797;
  border-bottom: 1px solid #3f3f3f;
`;
const Home = styled.div<{ borderBottom: string }>`
  padding-bottom: 5px;
  cursor: pointer;
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? "2px solid grey" : "none"};
`;
const Details = styled.div<{ borderBottom: string }>`
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? "2px solid grey" : "none"};
`;
const Card = styled.div<{ borderBottom: string }>`
  padding-bottom: 5px;
  cursor: pointer;
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? "2px solid grey" : "none"};
`;
const MenuWrap = styled.div`
  margin-right: 250px;
  margin-left: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 645px) {
    margin-right: 100px;
  }
  @media screen and (max-width: 475px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 369px) {
    font-size: 10px;
    margin-right: 50px;
  }
  @media screen and (max-width: 265px) {
    margin-right: 35px;
  }
  @media screen and (max-width: 190px) {
    margin-right: 15px;
    margin-left: 5px;
    font-size: 8px;
  }
  @media screen and (max-width: 130px) {
    margin-right: 5px;
    font-size: 5px;
  }
`;
const SubPages = styled.div``;
const Container1 = styled.div`
  display: flex;
  flex-direction: column;
  height: 88vh;
`;
const Icon = styled.div``;
const Head1 = styled.div`
  margin-top: 25px;
  margin-bottom: 5px;
  margin-left: 50px;
  display: flex;
  align-items: center;
  @media screen and (max-width: 230px) {
    margin-left: 0px;
    justify-content: center;
  }
`;
const TextHead = styled.div`
  color: white;
  font-weight: bold;
`;
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 699px) {
    align-items: center;
    justify-content: center;
  }
`;
const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100px;
  margin-left: 50px;
  padding-left: 30px;
  padding-right: 80px;
  border-right: 1px solid #424242;
  @media screen and (max-width: 699px) {
    display: none;
  }
`;
const SideBarWrap = styled.div`
  margin-top: 40px;
  max-width: 100px;
`;
const MainBar = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 50px;
  @media screen and (max-width: 410px) {
    padding-left: 0px;
  }
  @media screen and (max-width: 330px) {
    padding-left: 0px;
  }
`;
const DisplayName = styled.div`
  max-width: 100px;
  margin-top: 20px;
  color: #0044ff;
`;
const Image = styled.img`
  object-fit: cover;
  width: 100%;
  border-radius: 50%;
  height: 100%;
`;
const ImageWrap = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #003cff57;
`;
const SideBarImageFill = styled.div``;
const InputWrap = styled.div`
  display: flex;
  align-items: center;
  color: white;
  margin-top: 50px;
  margin-left: 20px;
  @media screen and (max-width: 330px) {
    margin-left: 0px;
    font-size: small;
  }
  font-size: x-large;
`;
const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  @media screen and (max-width: 330px) {
    align-items: center;
  }
`;
const ImageLabel = styled.label`
  height: 80px;
  width: 80px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: xx-large;
  color: #0044ff;
  background-color: #003cff57;
`;
const ImageInput = styled.input`
  display: none;
`;
const Input = styled.input`
  border: none;
  padding-bottom: 5px;
  border-bottom: 1px solid #424242;
  color: #0044ff;
  padding-left: 10px;
  outline: none;
  background-color: transparent;
  ::placeholder {
    color: gray;
  }
  @media screen and (max-width: 270px) {
    width: 110px;
  }
`;
const Input2 = styled.input`
  border: none;
  margin-left: 22px;
  border-bottom: 1px solid #424242;
  color: #0044ff;
  padding-left: 10px;
  padding-bottom: 5px;
  outline: none;
  background-color: transparent;
  ::placeholder {
    color: gray;
  }
  @media screen and (max-width: 270px) {
    width: 110px;
  }
`;
const Names = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 15px;
`;
const Nextbutton = styled.button`
  height: 30px;
  width: 100px;
  border-radius: 20px;
  margin-top: 20px;
  @media screen and (max-width: 320px) {
    font-size: 10px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0044ff;
  border: none;
  background-color: #003cff57;
  :hover {
    transform: scale(0.98);
    cursor: pointer;
  }
`;
const Containerx = styled.div`
  height: 88vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Wrapperx = styled.div`
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  border-radius: 360px;
  background-color: transparent;
`;
const ImageHold = styled.div`
  height: 56px;
  width: 56px;
  padding-top: 7px;
  padding-bottom: 7px;
`;
const Imagex = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
`;
const Name = styled.div`
  color: gray;
  font-family: cursive;
`;
const Text = styled.div`
  font-family: cursive;
  margin-top: 7px;
`;
const HoldDetails = styled.div`
  background-color: #252525d6;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;
const Down = styled.button`
  width: 200px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  border-radius: 5px;
  border: none;
  color: #363636;
  background-color: grey;
`;
const HoldImage = styled.div`
  width: 200px;
  height: 280px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;
const EventInvite = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`;
const Button1 = styled.label`
  width: 75px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  height: 25px;
  background-color: #2b2b2b;
  color: #7a7a7a;
  font-size: 10px;
  top: 80%;
  left: 50%;
`;
